@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {

    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}

#tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

